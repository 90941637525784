/**
 * ==================================================================================
 * https://www.npmjs.com/package/vue-cookies
 * ==================================================================================
 **/

export default {
  computed: {
    cookies() {
      return this.$cookies
    },
  },

  methods: {
    getCookie(name) {
      return this.hasCookies() ? this.cookies.get(name) : null
    },

    setCookie(name, value) {
      return this.hasCookies() ? this.cookies.set(name, value) : null
    },

    hasCookies() {
      return !!this.$cookies
    },
  },
}
