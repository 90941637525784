<template>
  <main-layout v-if="!loading">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </main-layout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout'
import { mapState, mapActions } from 'vuex'
import { get } from 'lodash'
import { validatePermissions } from '@/utils/auth'

export default {
  components: { MainLayout },
  data() {
    return {
      loading: true,
    }
  },

  computed: {
    ...mapState({
      isAuthenticated: (state) => state.auth.isAuthenticated,
      permissions: (state) => state.auth.permissions,
    }),
  },

  methods: {
    ...mapActions({
      getAuthenticatedUser: 'auth/getAuthenticatedUser',
    }),
  },

  created() {
    if (this.isAuthenticated) {
      this.getAuthenticatedUser().then(() => {
        const requiresPermissions = get(this.$route, 'meta.requiresPermissions')
        if (validatePermissions(requiresPermissions, this.permissions)) {
          this.loading = false
        } else {
          this.$router.push({
            name: 'home',
          })
        }
      })
    } else {
      this.$router.replace({ name: 'auth.login' })
    }
  },

  watch: {
    permissions(newValue) {
      const requiresPermissions = get(this.$route, 'meta.requiresPermissions')
      if (!validatePermissions(requiresPermissions, newValue)) {
        this.$router.push({
          name: 'home',
        })
      }
    },

    $route() {
      const requiresPermissions = get(this.$route, 'meta.requiresPermissions')
      const userPermissions = this.permissions ? this.permissions : []
      if (!validatePermissions(requiresPermissions, userPermissions)) {
        this.$router.push({
          name: 'home',
        })
      }
    },
  },
}
</script>
