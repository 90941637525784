<template>
  <v-snackbar
    v-model="show"
    :color="color"
    :timeout="-1"
    right
    class="global-snackbar"
  >
    {{ message }}

    <ErrorMessage v-if="list" :error="list" class="global-snackbar__list" />

    <v-btn text @click.prevent="setVisibility(false)">OK</v-btn>
  </v-snackbar>
</template>
<script>
/**
 * ==================================================================================
 * Global Snackbar
 * ==================================================================================
 **/
import { mapState, mapMutations } from 'vuex'
import ErrorMessage from '@/components/fields/ErrorMessage'

export default {
  components: {
    ErrorMessage,
  },

  data() {
    return {
      show: false,
      timer: null,
      timeout: 5000,
    }
  },

  computed: {
    ...mapState({
      message: (state) => state.snackbar.message,
      list: (state) => state.snackbar.list,
      color: (state) => state.snackbar.color,
    }),
  },

  watch: {
    show(value) {
      if (!value) {
        clearTimeout()
        this.reset()
      }
    },

    message(newValue, oldValue) {
      if (newValue) {
        this.setVisibility(true)
      } else {
        this.setVisibility(false)
      }
    },
  },

  methods: {
    ...mapMutations({
      setMessage: 'snackbar/setMessage',
      setList: 'snackbar/setList',
      setColor: 'snackbar/setColor',
    }),

    reset() {
      setTimeout(() => {
        this.setMessage(null)
        this.setList(null)
        this.setColor(null)
      }, 250)
    },

    /**
     * Create timer logic as v-snackbar `timeout` props
     * carries over to the next snackbar
     */
    setTimer(func) {
      clearTimeout(this.timer)
      this.timer = setTimeout(function () {
        func()
      }, this.timeout)
    },

    setVisibility(show) {
      this.$nextTick(() => {
        this.show = show

        if (show) {
          this.setTimer(() => {
            this.setVisibility(false)
          })
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.global-snackbar {
  ::v-deep &__list {
    color: white;

    .v-messages__wrapper {
      .v-messages__message {
        color: white;
      }
    }
  }
}
</style>
